export const nike = {
  /**
   * Do not edit directly
   * Generated on Tue, 04 May 2021 18:08:54 GMT
   */

  colorContentPrimary: "#111111",
  colorContentPrimaryInverse: "#ffffff",
  colorContentSecondary: "#757575",
  colorContentSecondaryInverse: "#cccccc",
  colorContentFocus: "#111111",
  colorContentActive: "#111111",
  colorContentDisabled: "#cccccc",
  colorContentError: "#d43f21",
  colorContentSuccess: "#128a09",
  colorBackgroundPrimary: "#ffffff",
  colorBackgroundAlwaysDark: "#111111",
  colorBackgroundAlwaysLight: "#ffffff",
  colorBorderBase: "#cccccc",
  colorBorderFocus: "#111111",
  colorBorderActive: "#111111",
  colorBorderDisabled: "#e5e5e5",
  colorBorderError: "#d43f21",
  colorFocusRing: "#f5f5f5",
  buttonBorderRadius: "30px",
  buttonBorderWidth: "1.5px",
  buttonBoxShadowWidth: "0",
  buttonBoxShadowHoverWidth: "0",
  buttonPaddingTopS: "4.5px",
  buttonPaddingTopM: "10.5px",
  buttonPaddingTopL: "16.5px",
  buttonPaddingBottomS: "4.5px",
  buttonPaddingBottomM: "10.5px",
  buttonPaddingBottomL: "16.5px",
  buttonPaddingSidesS: "18.5px",
  buttonPaddingSidesM: "22.5px",
  buttonPaddingSidesL: "22.5px",
  buttonPaddingUnderline: "0",
  buttonHeightS: "36px",
  buttonHeightM: "48px",
  buttonHeightL: "60px",
  buttonColorHover: "#757575",
  buttonColorHoverInverse: "#b2b2b2",
  dialogBackgroundBlur: "4px",
  elevation10: "10",
  elevation20: "20",
  elevation30: "30",
  elevation40: "40",
  elevation50: "50",
  elevation60: "60",
  elevation70: "70",
  elevation80: "80",
  elevation90: "90",
  elevation100: "100",
  fontWeightRegular: "400",
  fontWeightMedium: "500",
  fontWeightBold: "700",
  fontSizeXxs: "12px",
  fontSizeXs: "14px",
  fontSizeS: "16px",
  fontSizeM: "20px",
  fontSizeL: "24px",
  fontSizeXl: "28px",
  fontSizeXxl: "32px",
  fontSizeXxxl: "40px",
  fontSizeXxxxl: "48px",
  fontSizeXxxxxl: "72px",
  fontStyleNormal: "normal",
  paletteBlack: "#111111",
  paletteGrey1: "#757575",
  paletteGrey2: "#cccccc",
  paletteGrey3: "#e5e5e5",
  paletteGrey4: "#f5f5f5",
  paletteGrey5: "#fafafa",
  paletteWhite: "#ffffff",
  paletteError: "#d43f21",
  paletteSuccess: "#128a09",
  sizeSpacingXs: "4px",
  sizeSpacingS: "8px",
  sizeSpacingM: "12px",
  sizeSpacingL: "24px",
  sizeSpacingXl: "36px",
  sizeSpacingXxl: "60px",
  sizeSpacingXxxl: "84px",
  sizeSpacingXxxxl: "120px",
  sizeSpacingGridGutter: "16px",
  sizeSpacingGridGutterL: "12px",
  sizeSpacingGridExteriorGutterS: "24px",
  sizeSpacingGridExteriorGutterL: "48px",
  sizeBorderRadiusS: "4px",
  sizeBorderRadiusM: "8px",
  sizeBorderRadiusL: "12px",
  sizeBorderRadiusXl: "24px",
  sizeBorderWidthS: "1px",
  sizeBorderWidthM: "1.5px",
  sizeBorderWidthL: "2px",
  sizeIconS: "16px",
  sizeIconM: "24px",
  sizeIconL: "48px",
  sizeFormInputContainerHeight: "84px",
  sizeFormInputFieldHeight: "56px",
  transitionTimingFunctionEase: "cubic-bezier(.25, .1, .25, 1)",
  transitionDurationFast: "150ms",
  transitionDurationNormal: "250ms",
  breakpointXs: "320px",
  breakpointS: "600px",
  breakpointM: "960px",
  breakpointL: "1440px",
  breakpointXl: "1920px",
  formShadowFocus: "0 0 0 12px #f5f5f5",
  i18nTokens: {
    globalFallback: {
      typographyBody1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "72px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "48px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "44px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "40px",
      fontHeightXl: "40px",
      fontHeightXxl: "44px",
      fontHeightXxxl: "48px",
      fontHeightXxxxl: "72px",
      fontFamilyBase: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyBrand:
        '"Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif'
    },
    get "ca-es"() {
      return this.globalFallback;
    },
    get "cs-cz"() {
      return this.globalFallback;
    },
    get "da-dk"() {
      return this.globalFallback;
    },
    get "de-de"() {
      return this.globalFallback;
    },
    "el-gr": {
      typographyBody1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "60px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "34px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "36px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "32px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "32px",
      fontHeightXl: "34px",
      fontHeightXxl: "36px",
      fontHeightXxxl: "40px",
      fontHeightXxxxl: "60px",
      fontFamilyBase: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyBrand:
        '"Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif'
    },
    get "en-ca"() {
      return this["en-us"];
    },
    get "en-gb"() {
      return this["en-us"];
    },
    "en-us": {
      typographyBody1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "60px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "34px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "36px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "32px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "32px",
      fontHeightXl: "34px",
      fontHeightXxl: "36px",
      fontHeightXxxl: "40px",
      fontHeightXxxxl: "60px",
      fontFamilyBase: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyBrand:
        '"Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif'
    },
    get "es-ar"() {
      return this.globalFallback;
    },
    get "es-ca"() {
      return this.globalFallback;
    },
    get "es-cl"() {
      return this.globalFallback;
    },
    get "es-es"() {
      return this.globalFallback;
    },
    get "es-la"() {
      return this.globalFallback;
    },
    get "es-mx"() {
      return this.globalFallback;
    },
    get "es-uy"() {
      return this.globalFallback;
    },
    get "fr-fr"() {
      return this.globalFallback;
    },
    get "hu-hu"() {
      return this.globalFallback;
    },
    get "it-it"() {
      return this.globalFallback;
    },
    "jp-jp": {
      typographyBody1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", Meiryo, "メイリオ", "MS Pgothic", "ＭＳ Ｐゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "72px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", Meiryo, "メイリオ", "MS Pgothic", "ＭＳ Ｐゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "48px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", Meiryo, "メイリオ", "MS Pgothic", "ＭＳ Ｐゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "44px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "40px",
      fontHeightXl: "40px",
      fontHeightXxl: "44px",
      fontHeightXxxl: "48px",
      fontHeightXxxxl: "72px",
      fontFamilyBase:
        '"Helvetica Neue", Helvetica, Arial, "メイリオ", Meiryo, "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
      fontFamilyBrand:
        '"Nike TG", Meiryo, "メイリオ", "MS Pgothic", "ＭＳ Ｐゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", Meiryo, "メイリオ", "MS Pgothic", "ＭＳ Ｐゴシック", "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ", "Helvetica Neue", Helvetica, Arial, sans-serif'
    },
    "ko-kr": {
      typographyBody1: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", FBHanGothicDB, "Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "72px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", FBHanGothicDB, "Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "48px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", FBHanGothicDB, "Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "44px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily:
          '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "40px",
      fontHeightXl: "40px",
      fontHeightXxl: "44px",
      fontHeightXxxl: "48px",
      fontHeightXxxxl: "72px",
      fontFamilyBase:
        '"Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
      fontFamilyBrand:
        '"Nike TG", FBHanGothicDB, "Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", FBHanGothicDB, "Arial Bold", "Helvetica Neue", Helvetica, Arial, Dotum, "국어", Gulim, sans-serif'
    },
    get "nl-en"() {
      return this.globalFallback;
    },
    get "nl-nl"() {
      return this.globalFallback;
    },
    get "no-no"() {
      return this.globalFallback;
    },
    "pl-pl": {
      typographyBody1: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "72px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "48px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "44px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily:
          '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "40px",
      fontHeightXl: "40px",
      fontHeightXxl: "44px",
      fontHeightXxxl: "48px",
      fontHeightXxxxl: "72px",
      fontFamilyBase:
        '"Helvetica W02 Roman", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyBrand:
        '"Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif'
    },
    get "pt-br"() {
      return this.globalFallback;
    },
    get "pt-pt"() {
      return this.globalFallback;
    },
    "ru-ru": {
      typographyBody1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "72px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "48px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "44px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "40px",
      fontHeightXl: "40px",
      fontHeightXxl: "44px",
      fontHeightXxxl: "48px",
      fontHeightXxxxl: "72px",
      fontFamilyBase: '"Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyBrand:
        '"Nike TG", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif'
    },
    get "sv-se"() {
      return this.globalFallback;
    },
    "th-th": {
      typographyBody1: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, "Arial Bold", Arial, Tahoma, Thonburi, Ayuthaya, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "72px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, "Arial Bold", Arial, Tahoma, Thonburi, Ayuthaya, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "48px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, "Arial Bold", Arial, Tahoma, Thonburi, Ayuthaya, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "44px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily:
          '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "40px",
      fontHeightXl: "40px",
      fontHeightXxl: "44px",
      fontHeightXxxl: "48px",
      fontHeightXxxxl: "72px",
      fontFamilyBase:
        '"Helvetica Neue W31", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontFamilyBrand:
        '"Nike TG", "Helvetica Neue W31", "Helvetica Neue", Helvetica, "Arial Bold", Arial, Tahoma, Thonburi, Ayuthaya, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", "Helvetica Neue", Helvetica, "Arial Bold", Arial, Tahoma, Thonburi, Ayuthaya, sans-serif'
    },
    get "tr-tr"() {
      return this.globalFallback;
    },
    "zh-cn": {
      typographyBody1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "72px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "48px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "44px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "40px",
      fontHeightXl: "40px",
      fontHeightXxl: "44px",
      fontHeightXxxl: "48px",
      fontHeightXxxxl: "72px",
      fontFamilyBase:
        '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
      fontFamilyBrand:
        '"Nike TG", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif'
    },
    "zh-tw": {
      typographyBody1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody3: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyBody4: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyCta2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay1: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "72px",
        fontWeight: "700",
        lineHeight: "72px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay2: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "48px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyDisplay3: {
        fontFamily:
          '"Nike Futura", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "40px",
        fontWeight: "700",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading1: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "32px",
        fontWeight: "500",
        lineHeight: "44px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading2: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "28px",
        fontWeight: "500",
        lineHeight: "40px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading3: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "28px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading4: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      typographyHeading5: {
        fontFamily:
          '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        fontStyle: "normal",
        textTransform: "none"
      },
      fontHeightXxs: "14px",
      fontHeightXs: "20px",
      fontHeightS: "24px",
      fontHeightM: "28px",
      fontHeightL: "40px",
      fontHeightXl: "40px",
      fontHeightXxl: "44px",
      fontHeightXxxl: "48px",
      fontHeightXxxxl: "72px",
      fontFamilyBase:
        '"Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
      fontFamilyBrand:
        '"Nike TG", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif',
      fontFamilyMarketing:
        '"Nike Futura", "Helvetica Neue", Helvetica, Arial, "PingFang", "Microsoft YaHei", "雅黑体", SimHei, sans-serif'
    }
  }
};
